<template>
  <div class='px-4 py-5'>
    <div class='text-center'>
      <div class='r---text-size-1 r---text-color-5 mb-5'>Order #{{ order_reference }}</div>
      <h1 class='r---text-size-1 r---text-color-5 mb-5'>Payable balance: {{ payable_balance }}</h1>
    </div>

    <!-- <OrderPayment /> -->
    <template v-if='this.supplier && this.order'>
      <Yoco v-if='is_yoco' :order_id='this.order.id' :supplier='supplier' @completed='transaction_success' />
      <PayGate v-if='is_paygate' :order_id='this.order.id' :supplier='supplier' @completed='transaction_success' />
      <MoyaPay v-if='is_moyapay' :order_id='this.order.id' :supplier='supplier' @completed='transaction_success' />
    </template>

    <div v-else-if='loading' class='d-flex flex-column align-center justify-center py-4'>
      <p class='mt-4'>Preparing payment information</p>
      <v-progress-circular indeterminate color="primary" />
    </div>

    <template v-else>Something went wrong</template>

  </div>
</template>

<script>
import { number_to_currency } from '@/lib/helpers'
import LoadingSpinner from '@/components/loading_spinner'
import Yoco from '@/components/payment/yoco'
import MoyaPay from '@/components/payment/moya'
import PayGate from '@/components/payment/pay_gate'

export default {
  components: { LoadingSpinner, MoyaPay, PayGate, Yoco },

  created() {
    // fetch order
    this.loading = true
    this.$store.dispatch('orders/fetch', this.$route.params.id)
    .then((order) => {
      if (order.status == 'pending_payment' && order.provider.toLowerCase() in ['moya', 'paygate', 'yoco']) {
        this.$store.dispatch('suppliers/fetch')
        .then(() => { this.$store.dispatch('suppliers/select', this.order.supplier_id) })
        // handle supplier not found
        .finally(() => { this.loading = false })
      } else {
        this.$router.replace(`/orders/${this.order.id}`)
        this.$store.dispatch('layout/notify', 'Order is paid in full')
      }
    })
    .catch((error) => {
      this.$router.replace(`/orders/${this.order.id}`)
      console.error(`/orders/${this.$route.params.id}/pay get_order error: ${error}`)
    })
  },

  data() { return {
    loading: false,
    icon_image_url: this.$store.getters['application/icon_image_url'],
    application_title: this.$store.state.application.meta.title,
  }},

  computed: {
    order() {
      return this.$store.state.orders.current
    },

    supplier() {
      return this.$store.getters["suppliers/current"]
    },

    order_reference() {
      return this.order?.reference
    },

    payable_balance() {
      return number_to_currency({
        symbol: this.$store.getters['application/currency'],
        cents: this.order?.payable_balance_in_cents
      })
    },

    is_moyapay(){
      return this.has_provider('MOYA')
    },

    is_paygate(){
      return this.has_provider('PAYGATE')
    },

    is_yoco(){
      return this.has_provider('YOCO')
    }
  },

  methods: {
    transaction_success: function() {
      this.$router.replace(`/orders/${this.order.id}`)
    },

    has_provider: function(provider) {
      return this.$store.getters['orders/has_provider'](provider)
    }
  }

}
</script>

<style>

</style>
